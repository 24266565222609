import React, { useState } from 'react';

import Layout from '../../components/layout';
import Seo from '../../components/seo';
import TabNav from '../../components/TabNav';
import WithSidebar from '../../components/WithSidebar';

import { theme } from '../../styles/theme';

const tabs = [
  {
    text: 'RBC',
    colour: 'red',
  },
  {
    text: 'PLT',
    colour: 'purple',
  },
  {
    text: 'FFP/CRYO',
    colour: 'yellow',
  },
];

const NeonatesPage = () => {
  const [currentView, setCurrentView] = useState('RBC');

  return (
    <Layout navTitle="Neonates">
      <Seo title="Blood Components - Neonates" />
      <WithSidebar>
        <TabNav
          label="Component choice"
          tabs={tabs}
          active={currentView}
          onClick={text => setCurrentView(text)}
        />

        <div>
          {currentView === 'RBC' && (
            <article
              css={theme.components.rowSmall}
              className="theme"
              style={{ '--theme': theme.colours.red }}>
              <h1>Red cells for top-up transfusions</h1>
              <p>Studies support restrictive transfusion thresholds.</p>
              <h2>Suggested transfusion thresholds for preterm neonates</h2>

              <table className="table">
                <thead>
                  <tr>
                    <th rowSpan="2">Postnatal Age</th>
                    <th colSpan="3">
                      Suggested transfusion threshold
                      <br />
                      Hb (g/L)
                    </th>
                  </tr>
                  <tr>
                    <th>Ventilated</th>
                    <th>On oxygen/NIPPV**</th>
                    <th>Off oxygen</th>
                  </tr>
                </thead>
                <tbody className="text-center">
                  <tr>
                    <td>1st 24 hours</td>
                    <td>&lt;120</td>
                    <td>&lt;120</td>
                    <td>&lt;100</td>
                  </tr>
                  <tr>
                    <td>&le;week 1 (day 1-7)</td>
                    <td>&lt;120</td>
                    <td>&lt;100</td>
                    <td>&lt;100</td>
                  </tr>
                  <tr>
                    <td>week 2 (day 8-14)</td>
                    <td>&lt;100</td>
                    <td>&lt;95</td>
                    <td>&lt;75*</td>
                  </tr>
                  <tr>
                    <td>&ge;week 3 (day 15 onwards)</td>
                    <td>&lt;100</td>
                    <td>&lt;85</td>
                    <td>&lt;75*</td>
                  </tr>
                </tbody>
              </table>

              <p>
                <small>
                  <em>
                    Table applies to very preterm babies (&lt;32 weeks); for
                    later preterm/term babies the values for babies off oxygen
                    may be used.
                  </em>
                </small>
              </p>

              <p>
                <small>
                  <em>
                    * It is accepted that clinicians may use up to 85 g/L
                    depending on clinical situation.
                  </em>
                </small>
              </p>

              <p>
                <small>
                  <em>** NIPPV, non-invasive positive pressure ventilation.</em>
                </small>
              </p>

              <ul className="pt-gap">
                <li>
                  <p className="text-bold">
                    Generally transfuse 15 mL/kg for non-bleeding neonates.
                  </p>
                </li>
                <li>
                  <p className="text-bold">
                    Where the term or preterm neonate does not require
                    resuscitation, undertake delayed cord clamping.
                  </p>
                </li>
                <li>
                  <p className="text-bold">
                    Minimise phlebotomy where possible, using small volume
                    samples.
                  </p>
                </li>
                <li>
                  <p className="text-bold">
                    Ensure that paedipacks are available for emergency use by
                    maternity and neonatal units.
                  </p>
                </li>
                <li>
                  <p className="text-bold">
                    Transfuse red cells for large volume neonatal and infant
                    transfusion before the end of Day 5
                  </p>
                  <p>Transfusion rate: 5mL/kg/hr.</p>
                </li>
              </ul>
            </article>
          )}

          {currentView === 'FFP/CRYO' && (
            <article
              css={theme.components.rowSmall}
              className="theme"
              style={{ '--theme': theme.colours.yellow }}>
              <h1 className="mb-gap">
                Fresh frozen plasma and cryoprecipitate
              </h1>
              <p>
                Routine coagulation screening is inappropriate: results are
                difficult to interpret in neonates and routine testing may lead
                to increased FFP transfusion without benefit.
              </p>

              <ul>
                <li>
                  <p className="text-bold">
                    FFP should not be used routinely to try to correct
                    abnormalities of the coagulation screen alone in nonbleeding
                    neonates.
                  </p>
                </li>
                <li>
                  <p className="text-bold">
                    FFP may be of benefit in neonates with clinically
                    significant bleeding or prior to invasive procedures with
                    risk of significant bleeding, and who have abnormal
                    coagulation (PT/APTT significantly above the gestational and
                    postnatal age-related range).
                  </p>
                </li>
                <li>
                  <p className="text-bold">
                    FFP should not be used for simple volume replacement or
                    routinely for prevention of IVH.
                  </p>
                  <p>Typical transfusion volumes: FFP 15-20 mL/kg</p>
                </li>
                <li>
                  <p className="text-bold">
                    Cryoprecipitate should not be used routinely for nonbleeding
                    neonates with decreased fibrinogen. It may be considered for
                    fibrinogen &lt;1g/L for surgery at risk of significant
                    bleeding or to critical sites.
                  </p>
                  <p>
                    Typical transfusion volumes: cryo 5-10 mL/kg; rate 10-20
                    mL/kg/hr.
                  </p>
                </li>
              </ul>
            </article>
          )}

          {currentView === 'PLT' && (
            <article
              css={theme.components.rowSmall}
              className="theme"
              style={{ '--theme': theme.colours.purple }}>
              <h1 className="mb-gap">Platelets</h1>
              <p>
                For preterm neonates with platelets &lt;25 x10{'\u2079'}/L,
                transfuse platelets and treat the underlying cause of
                thrombocytopenia. For non-bleeding neonates platelet
                transfusions should not be routinely administered if platelet
                count is ≥25 × 10
                {'\u2079'}/L.
              </p>
              <h2>Suggested transfusion thresholds for preterm neonates</h2>

              <table className="table">
                <thead>
                  <tr>
                    <th>
                      Platelet count <br /> (x10{'\u2079'}/L)
                    </th>
                    <th>Indication for platelet transfusion</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="text-center text-bold">&lt;25</td>
                    <td>
                      Neonates with no bleeding (including neonates with NAIT if
                      no bleeding and no family history of ICH).
                    </td>
                  </tr>
                  <tr>
                    <td className="text-center text-bold">&lt;50</td>
                    <td>
                      Neonates with bleeding, current coagulopathy, before
                      surgery, or infants with NAIT if previously affected
                      sibling with ICH.
                    </td>
                  </tr>
                  <tr>
                    <td className="text-center text-bold">&lt;100</td>
                    <td>
                      Neonates with major bleeding or requiring major surgery
                      (e.g. neurosurgery).
                    </td>
                  </tr>
                </tbody>
              </table>

              <p>
                <small>
                  <em>
                    Table applies to preterm babies; clinicians may also choose
                    to use for term babies. NAIT, neonatal immune
                    thrombocytopenia; ICH, intracranial haemorrhage.
                  </em>
                </small>
              </p>

              <p>
                <small>
                  <em>
                    Typical transfusion volume: 10-20 mL/kg; rate 10-20
                    mL/kg/hr.
                  </em>
                </small>
              </p>
            </article>
          )}
        </div>
      </WithSidebar>
    </Layout>
  );
};

export default NeonatesPage;
